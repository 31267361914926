/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import Image from 'components/shared/Image'
import ImageBackground from 'components/shared/ImageBackground'
import ParseContent from 'components/shared/ParseContent'
import Assortiment from 'components/shared/Assortiment'
import Markten from 'components/shared/Markten'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'

// Blog
import Blog from 'components/related/Blog'
import Blogik, { BlogConsumer, BlogButton, BlogPagination } from 'components/shared/Blogik'


// Images
import BgAanbieding from 'img/bg-aanbieding.png'

const Background = styled(ImageBackground)`
  width: 760px;
  height: 500px;

  @media (max-width: 991px) {
    width: 100%;
    height: 350px;
  }
`

const BGImage = styled(ImageBackground)`
  width: 380px;
  height: 500px;

  @media (max-width: 991px) {
    width: 100%;
    height: 310px;
  }
`

const Block = styled.div`
  background: ${props => props.theme.color.face.light};
  bottom: 100px;
  left: 60px;
  z-index: 1;

  @media (max-width: 991px) {
    bottom: 0px;
    left: 30px;
  }
`
const ContentBlock = styled.div`
  z-index: 1;

  & h2 {
    color: ${props => props.theme.color.text.secondary};

    & span {
      color: ${props => props.theme.color.text.light};
    }
  }
`

const Price = styled(ParseContent)`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.xxl};
  line-height: ${props => props.theme.font.size.xml};
  margin-bottom: 0 !important;

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.l};
    line-height: ${props => props.theme.font.size.m};
  }
`

const PriceSecond = styled(ParseContent)`
  font-family: ${props => props.theme.font.family.secondary};
  color: ${props => props.theme.color.face.contrast} !important;
  font-size: ${props => props.theme.font.size.xxl};
  line-height: ${props => props.theme.font.size.xml};
  margin-bottom: 2rem !important;

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.l};
    line-height: ${props => props.theme.font.size.m};
    margin-bottom: 1.5rem !important;
  }
`

const MarketImage = styled.div`
  border-right: 2px solid ${props => props.theme.color.face.main};
`

const QuoteSection = styled.section`
  background-color: rgba(25,153,214,0.45);
`

const OfferContent = styled(ParseContent)`
 font-size: ${props => props.theme.font.size.m};
`

const QuoteImage = styled(Image)`
  width: 100%;
`

const QuoteBlock = styled.div`
  background-color: ${props => props.theme.color.face.light};
  filter: drop-shadow(0px 0px 4px rgba(0,0,0,.50));
`

const Content = styled(ParseContent)`
  & h3 {
    font-size: ${props => props.theme.font.size.xsl};
    line-height: ${props => props.theme.font.size.xsl};
    margin-bottom: 0;
    text-align: center;

    & span {
      color: ${props => props.theme.color.text.secondary};
    }
  }
`

const HomeTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.blockLeft.image.localFile && acf.banner.blockLeft.image.localFile.childImageSharp.openGraph.src }}
      />

      <section>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-8 p-lg-0 position-relative mb-4 mb-lg-0">
              <Block className="col-lg-6 col-10 pt-4 pt-lg-5 px-4 position-absolute mb-4 mb-lg-0">
                <ParseContent className="mb-0 pb-4 px-2" content={acf.banner.blockLeft.description} />
                <ButtonDefault className="mb-n3" to={acf.banner.blockLeft.button.url}>{acf.banner.blockLeft.button.title}</ButtonDefault>
              </Block>
              <Background src={acf.banner.blockLeft.image} />
            </div>
            <div className="col-lg-4 position-relative">
              <ContentBlock className="position-absolute p-4">
                <ParseContent content={acf.banner.blockRight.title} className="mb-3 mb-lg-5" />
                <OfferContent content={acf.banner.blockRight.description} className="text-light mb-3 mb-lg-5 pb-1" />
                
                <ButtonDefault to={acf.banner.blockRight.button.url} isAnchor>{acf.banner.blockRight.button.title}</ButtonDefault>
              </ContentBlock>
              <BGImage src={BgAanbieding} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row pt-lg-4 pb-lg-5">
            <Assortiment />
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row py-5">
            <MarketImage className="col-lg-7 py-4">
              <ParseContent content={acf.markets.title} className="mb-5" />
              <Image src={acf.markets.image.localFile.publicURL} />
            </MarketImage>
            <div className="col-lg-5 pt-3 pl-5">
              <Markten />
            </div>
          </div>
        </div>
      </section>

      <QuoteSection>
        <div className="container mt-5">
          <div className="row pb-5 justify-content-center">
            <QuoteBlock className="col-lg-7 p-3 mx-3 mx-lg-0 mb-5 mt-n5">
              <Content content={acf.quote.text} />
            </QuoteBlock>
            <QuoteImage src={acf.quote.image.localFile.publicURL} />
          </div>
        </div>
      </QuoteSection>

      <section>
        <div className="container pt-lg-4">
          <div className="row pt-5 pb-3">
            <div className="col-12">
              <ParseContent content={acf.reciepes.title} />
            </div>
          </div>
          <div className="row pb-lg-5">
            <Blogik
                postType="blog"
                settings={{
                  limit: 3
                }}
              >
                <BlogConsumer>
                  {({ posts }) => {
                    return (
                      <>
                        <Blog posts={posts} />
                      </>
                    )
                  }}
                </BlogConsumer>
              </Blogik>
          </div>
        </div>
      </section>
      
    </Layout>
  )
}

export default HomeTemplate