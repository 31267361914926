import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Images
import ButtonArrow from 'img/button-arrow.inline.svg'

const CustomImage = styled(Image)`
  height: 60px;
  width: auto;
`

const Title = styled.h4`
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.text.contrast};
`

const AssortimentItem = styled.div`
  @media (min-width: 991px){
    & div.right {
      order: 2;
    }
  }
`

const Arrow = styled(ButtonArrow)`
  
`

const AssortimentImage = styled(Image)`
  width: 420px;
  max-height: 245px;
`

const Assortiment = ({ noImage, allVisible, right }) => {
 const { item } = useStaticQuery(graphql`
  {
    item: allWordpressWpAssortiment(sort: {fields: date}) {
      edges {
        node {
          acf {
            featured {
              icon {
                localFile {
                  publicURL
                }
              }
              title
            }

            overview {
              description
              image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`) 
  return (
    item.edges.map(({ node: { acf: { featured, overview } } }, index) => (
      allVisible ? (
        <AssortimentItem id={featured.title} className={`row py-4 py-lg-5  `} key={index}>
          <div className={`col-lg-6 pr-lg-5 ${index %2 === 0 ? 'left' : 'right'} `}>
            <CustomImage src={featured.icon} className="mb-4" />
            <Title>{featured.title}</Title>
            <ParseContent className="pr-lg-5" content={overview.description} />
          </div>
          <div className="col-lg-6">
            <AssortimentImage src={overview.image.localFile.publicURL} />
          </div>
        </AssortimentItem>
      ) : (
        <div className={`col-6 d-flex flex-column mb-lg-0 ${noImage ? 'align-items-start pl-0 mb-2' : 'col-md-4 col-lg-2 align-items-center mb-5'} `} noImage={noImage}>
          {!noImage && (
            <CustomImage src={featured.icon} className="mb-4" />
          )}
          <Title className={` ${noImage ? 'text-left d-flex' : 'text-center'} `}>
            {noImage && (
              <Arrow className="mr-2" />
            )}
            <a href={`/assortiment#${featured.title}`}>{featured.title}</a>
          </Title>
        </div>
      )
    ))
  )
}

export default Assortiment