/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ImageBackground from 'components/shared/ImageBackground'

const Hero = styled(ImageBackground)`
  @media (min-width: 992px) {
    min-height: 250px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }
`

const Content = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};
  text-shadow: 0 0 4px rgba(0, 0, 0, .85);
`

const HeroDefault = ({ fields }) => (
  <Hero src={fields.image} aboveFold>
    <div className="container py-5">
      <Content content={fields.description} />
    </div>
  </Hero>
)

export default HeroDefault